import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { ICurrentUser } from '../models/current-user.model';

@Injectable({
  providedIn: 'root',
})

export class CurrentUserService {
  private tokenKey = 'token';
  private CLAIMS = {
    name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  };

  getUserInfo(): ICurrentUser | null {
    const token = localStorage.getItem(this.tokenKey);
    if (token) {
      try {
        const decoded = jwtDecode(token) as any;
        return {
          name: decoded[this.CLAIMS.name] || '',
          role: decoded[this.CLAIMS.role] || '',
        } as ICurrentUser;
      } catch (error) {
        console.error('Error decoding token:', error);
        return null;
      }
    }
    return null;
  }
}
